var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("img", { attrs: { src: _vm.sale.icon } }),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "name" },
        [
          _vm._v(" " + _vm._s(_vm.sale.name) + " "),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.sale.desc,
                placement: "top",
              },
            },
            [_c("i", { staticClass: "el-icon-question" })]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "value" }, [
        _vm._v(" " + _vm._s(_vm.sale.value) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }