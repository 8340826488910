<template>
  <div class="card">
    <img :src="sale.icon" />
    <div class="content">
      <div class="name">
        {{ sale.name }}
        <el-tooltip
          class="item"
          effect="dark"
          :content="sale.desc"
          placement="top"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="value">
        {{ sale.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sale: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
  img {
    max-width: 50%
  }
  .content {
    padding-left: 12px;
    box-sizing: border-box;
    text-align: center;
    .name {
    font-size: 12px;
    color: #989898;
    padding: 12px 0;
  }
  .value {
    font-size: 28px;
    font-weight: 500;
    padding: 12px 0;
  }
  }

}
</style>
