<template>
  <div>
    <div class="app-container">
      <div class="outer">
        <div
          v-loading.body="fullscreenLoading"
          class="bashboard"
        >
          <div class="card">
            <div class="header">
              <div class="card-title">
                销售统计
              </div>
              <el-date-picker
                v-model="dateRange"
                size="mini"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="datePickerChange"
              >
              </el-date-picker>
            </div>
            <div class="list">
              <saleCard
                v-for="item in saleList"
                :key="item.key"
                :sale="item"
              ></saleCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import saleCard from './components/saleCard'

export default {
  name: 'Bashboard',
  components: {
    saleCard
  },
  data() {
    return {
      dateRange: [],
      fullscreenLoading: false, // 加载中
      // saleCard数据
      saleList: [{
        name: '总销售额',
        key: 'salesAmount',
        desc: '统计时间内，成功付款的订单金额总和（包含优惠券金额，不剔除退款金额）',
        value: '',
        icon: 'https://img.hzanchu.com/acimg/4e164bbca3518e2a43534527120b301b.png'
      }, {
        name: '待发货数',
        key: 'waitDeliveryCount',
        desc: '统计时间内，商品为待发货状态下的订单总数',
        value: '',
        icon: 'https://img.hzanchu.com/acimg/98ed8bb0cd90a9a6a34d1b05227f7c5e.png'
      }, {
        name: '订单数',
        key: 'orderCount',
        desc: '统计时间内，生成订单的总数量（包含提交后支付成功和未支付的订单数）',
        value: '',
        icon: 'https://img.hzanchu.com/acimg/102e216ad3cd7d139bb97f170b64d879.png'
      }, {
        name: '支付订单数',
        key: 'payOrderCount',
        desc: '统计时间内，成功付款的订单数量',
        value: '',
        icon: 'https://img.hzanchu.com/acimg/28d3921d523f0f3abe938e657841c746.png'
      }, {
        name: '退款订单量 (单)',
        key: 'refundOrderCount',
        desc: '统计时间内，提交退款申请的订单总数量',
        value: '',
        icon: 'https://img.hzanchu.com/acimg/23977b121c1eb34b1d7983a67e6f547b.png'
      }]
    }
  },
  mounted() {
    this.getTimeRange()
    this.getSaleInfo()
  },
  methods: {
    // 获取销售数据
    getSaleInfo() {
      this.$axios.get(this.$api.homePage.sales, {
        params: {
          beginTime: this.dateRange[0],
          endTime: this.dateRange[1]
        }
      }).then(res => {
        let r = res.data || {}
        // 销量统计
        this.saleList.forEach(item => {
          item.value = r[item.key]
          return item
        })
      })
    },
    getTimeRange() {
      let end = Moment()
      let start = Moment(end).startOf('day')
      this.value = [start.valueOf(), end.valueOf()]
      this.dateRange = [
        start.format('YYYY-MM-DD HH:mm:ss'),
        end.format('YYYY-MM-DD HH:mm:ss')
      ]
    },
    datePickerChange(value) {
      this.dateRange = [
        Moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
        Moment(value[1]).hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss')
      ]
      this.getSaleInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.outer {
  background: transparent;
  padding: 0;
}
.bashboard {
  min-height: 700px;
}
.title {
  margin-top: 100px;
  font-size: 60px;
  color: #000000a6;
  text-align: center;
}
.card {
  margin: 0 0 20px;
  padding: 20px;
  background: #fff;
  .header {
    display: flex;
    align-items: center;
    .card-title {
    font-weight: bolder;
    font-size: 18px;
    color: #222;
    margin-left: 10px;
    margin-right: 21px;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
  }
}

</style>
