var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "app-container" }, [
      _c("div", { staticClass: "outer" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading.body",
                value: _vm.fullscreenLoading,
                expression: "fullscreenLoading",
                modifiers: { body: true },
              },
            ],
            staticClass: "bashboard",
          },
          [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "header" },
                [
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v(" 销售统计 "),
                  ]),
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "daterange",
                      align: "right",
                      "unlink-panels": "",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.datePickerChange },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.saleList, function (item) {
                  return _c("saleCard", {
                    key: item.key,
                    attrs: { sale: item },
                  })
                }),
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }